<template>
  <div id="BSCM" class="buybox supernettFont" > 
    <div class="buybox_right">
      <h2 class=" wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">What is BSCM ?</h2>
      <div class=" wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2s">
        <img src="@/assets/image/bscm/bscm_01.png" v-if="!$utils.isMobile()" alt="">
        <img src="@/assets/image/bscm/bscm_01m.png" v-else alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted(){
    var options={
        //默认为true
        live:false
    }
    new this.$WOW.WOW(options).init();
  }
}
</script>

<style lang="scss" scoped>
.buybox{
  display: flex;
  justify-content: center;
  margin-top: 80px;
  position: relative;
  padding-bottom: 100px;
}
.buybox::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 0px;
  background: url('../../assets/image/line.png') no-repeat center center/100%;
}
.buybox_right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2{
    color: #F8C10A;
    font-size: 36px; 
    text-transform: uppercase;
    margin: 14px 0px 32px;
  }
}
@media screen and (max-width:768px) {
  .buybox{
    width: 100%;
    margin-top: 80px;
    padding-bottom: 30px;
    &::before{
      display: none;
    }
    h2{
      font-size: 30px;
      margin: 0px 0px 50px;
    }

  }
  .buybox_right{
  }
}
</style>